import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import "./modalStyle.css";

export const EditModalForm = (props) => {
  const [editedNoteId, setEditedNoteId] = useState("");
  const [editedEventId, setEditedEventId] = useState("");
  const [editedNoteTitle, setEditedNoteTitle] = useState("");
  const [editedProducList, setEditedProducList] = useState("");
  const [editedNoteContent, setEditedNoteContent] = useState("");
  const [editedNoteCompanyList, setEditedNoteCompanyList] = useState("");
  const [editedNoteAdditionalInfo, setEditedNoteAdditionalInfo] = useState("");
  //const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);


  const handleChangeProductListConversion = (productList) => {
    let productListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < productList.length; i++) {
        productListArr.push(Object.values(productList[i]).toString());
    }
    return productListArr;
  }


  useEffect(() => {
    // console.log("inside UseEffect");
    setEditedNoteId(props.rowDataForEdit.note_id);
    setEditedEventId(props.rowDataForEdit.event_id);
    setEditedNoteTitle(props.rowDataForEdit.title);
    setEditedProducList(props.rowDataForEdit.product_id);
    setEditedNoteContent(props.rowDataForEdit.content);
    setEditedNoteCompanyList(props.rowDataForEdit.company_id);
    setEditedNoteAdditionalInfo(props.rowDataForEdit.additional_info);
    setProductList(handleChangeProductListConversion(props.productList));
    
    //setUserList(props.userList);
    //console.log("____________________");
    
    //console.log(userList);
    //console.log("____________________");
    // console.log(editedNoteId);
    // console.log(editedEventId);
    // console.log(editedProducList);
  }, [props]);

  const onSelectProductList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedProducList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveProductList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedProducList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectCompanyList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedNoteCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveCompanyList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedNoteCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // console.log(editedNoteId);
    // console.log(editedEventId);
    // console.log(editedNoteTitle);
    // console.log(editedProducList);
    props.handleTableDataEdit(
      editedNoteId,
      editedNoteTitle,
    editedEventId,
    editedNoteCompanyList,
    editedProducList,
    editedNoteContent,
    editedNoteAdditionalInfo
    );
    props.onHide();
  };
  // console.log(props.rowDataForEdit.note_id);
  // console.log(props.rowDataForEdit.title);
  // console.log("ORE MAMA" + props.companyList);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: "99999", width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden", background: "202124"  }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Note Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
        <h4>Event Name: {props.eventName}</h4>
        <h4>Note ID: {props.rowDataForEdit.note_id}</h4>
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Note title</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={`${editedNoteTitle}`}
              onChange={(e) => setEditedNoteTitle(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Company List</Form.Label>
            {/* <Form.Control
              value={`${editedProductUxtech}`}
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              selectedValues={`${props.rowDataForEdit.company_id}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              onRemove={onRemoveCompanyList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectCompanyList}
              options={props.companyList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Product List</Form.Label>
            <Multiselect
              selectedValues={`${props.rowDataForEdit.product_id}`.split(", ")}
              showCheckbox
              singleSelect={true}
              hidePlaceholder={false}
              closeOnSelect={true}
              isObject={false}
              onRemove={onRemoveProductList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectProductList}
              options={productList}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Note Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={`${editedNoteContent}`}
              onChange={(e) => setEditedNoteContent(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Additional Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={`${editedNoteAdditionalInfo}`}
              onChange={(e) => setEditedNoteAdditionalInfo(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Save
          </Button>
        </Form>
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export const AddModalForm = (props) => {
  // const [editedProductId, setEditedProductId] = useState("");
  //const [editedNoteId, setEditedNoteId] = useState("");
  // const [editedEventId, setEditedEventId] = useState("");
  const [editedNoteTitle, setEditedNoteTitle] = useState("");
  const [editedProducList, setEditedProducList] = useState("");
  const [editedNoteContent, setEditedNoteContent] = useState("");
  const [editedNoteCompanyList, setEditedNoteCompanyList] = useState("");
  const [editedNoteAdditionalInfo, setEditedNoteAdditionalInfo] = useState("");
  const [productList, setProductList] = useState([]);


  const handleChangeProductListConversion = (productList) => {
    let productListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < productList.length; i++) {
        productListArr.push(Object.values(productList[i]).toString());
    }
    return productListArr;
  }

  useEffect(() => {
    // console.log("inside UseEffect");
    setProductList(handleChangeProductListConversion(props.productList));
    
    // setEditedProductId(props.rowDataForEdit.product_id);
    // setEditedProductName(props.rowDataForEdit.name);
    // setEditedProductUxSales(props.rowDataForEdit.ux_sales);
    // setEditedProductUxtech(props.rowDataForEdit.ux_tech);
    // console.log(editedProductName);
    // console.log(editedProductUxSales);
  }, [props]);

  const onSelectProductList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedProducList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveProductList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedProducList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectCompanyList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedNoteCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveCompanyList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedNoteCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // //console.log(editedNoteId);
    // console.log(editedEventId);
    // console.log(editedNoteTitle);
    // console.log(editedProducList);
    props.handleNewTableDataAdd(
      editedNoteTitle,
      props.addForEventId,
      editedNoteCompanyList,
      editedProducList,
      editedNoteContent,
      editedNoteAdditionalInfo
    );
    props.onHide();
  };
  //console.log(props.rowDataForEdit.name);
  //console.log(props.rowDataForEdit.product_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: "99999", width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden" , background: "202124" }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          
          Add Note Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
      {/* <h4>Event ID: {props.rowDataForEdit.event_id}</h4> */}
        <h4>Event Name: {props.eventName}</h4>
        <h4>New Note Entry</h4>
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Note title</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Enter Note Title"
              onChange={(e) => setEditedNoteTitle(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}

          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Company List</Form.Label>
            {/* <Form.Control
              value={`${editedProductUxtech}`}
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              //selectedValues={`${props.rowDataForEdit.company_id}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              onRemove={onRemoveCompanyList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectCompanyList}
              options={props.companyList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Product List</Form.Label>
            <Multiselect
              //selectedValues={`${props.rowDataForEdit.product_id}`.split(", ")}
              showCheckbox
              singleSelect={true}
              hidePlaceholder={false}
              closeOnSelect={true}
              isObject={false}
              onRemove={onRemoveProductList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectProductList}
              options={productList}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Note Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              //value={`${editedNoteContent}`}
              placeholder="Enter Note Content"
              onChange={(e) => setEditedNoteContent(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Additional Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              //value={`${editedNoteAdditionalInfo}`}
              placeholder="Enter Additional Info"
              onChange={(e) => setEditedNoteAdditionalInfo(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Add
          </Button>
        </Form>
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}; 