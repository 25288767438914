import React, { useState }from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
//import tableColumns from "../ProductTable/TableColumns";


// Create styles
const styles = StyleSheet.create({
//   document: {
//     width: "80vw",
//     backgroundImage: 'url("../certificateBackGround.png")',
//     height: "80vh",
//   },
//   page: {
//     flexDirection: "column",
//     backgroundImage: 'url("../certificateBackGround.png")',
//     height: "500px",
//   },
page:{
  paddingBottom: 65,
  paddingTop: 60,
},
  header: {
    padding: 5,
    backgroundColor: "blue",
  },
  header2: {
    marginTop: "5px",
    padding: 5,
  },
  name: {
    marginTop: "1px",
    padding: "15px",
  },
  headerText: {
    fontSize: "50px",
    textAlign: "center",
    padding: "5px",
  },
  headerText2: {
    fontSize: "25px",
    textAlign: "center",
  },
  bodySection: {
    marginTop: "5px",
    padding: "15px",
  },
  bodyText: {
    fontSize: "10px",
    textAlign: "left",
    marginLeft: "30px",
  },
  bodyTextBold: {
    fontSize: "11px",
    textAlign: "left",
    marginLeft: "15px",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  bodyTextBold1: {
    fontSize: "11px",
    textAlign: "left",
    marginLeft: "30px",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  bodyTextUnbold: {
    fontSize: "10px",
    textAlign: "left",
    marginLeft: "2px",
    display: "flex",
    flexDirection: "row",
  },
  bodyText1: {
    marginTop: "1px",
    fontSize: "16px",
    textAlign: "left",
    marginLeft: "25px",
  },
  bodyText2: {
    marginTop:"10px",
    fontSize: "10px",
    textAlign: "left",
    marginLeft: "65%",
  },
  noteText: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    textDecoration: "underline",
    
  },
  
  contentText: {
    fontSize: "10px",
    textAlign: "left",
    marginLeft: "40px",
    padding:"10px",
    border: "1px",
    width: "380px",
    fontWeight: "bold",
    
  },
  contentText1: {
    fontSize: "10px",
    textAlign: "left",
    marginLeft: "30px",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop:"0px",
    paddingLeft:"10px",
    paddingBottom: "5px",
    paddingRight: "10px",
    border: "1px",
    width: "445px",
    fontWeight: "bold",
    
  },
  contentBorder:{
    marginLeft:"40px",
    marginTop: "10px",
    width: "460px",
  },
  conclusionSection: {
    marginTop: "2px",
    padding: "10px",
  },
  noteSection: {
    marginTop: "2px",
    padding: "10px",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
    //width: "100px",
    height: "80%",
    marginBottom: "5px",
  },
  signatureBody: {
    textAlign: "right",
  },
  signature: {
    marginLeft: "70%",
    marginTop: "5px",
    width: "20%",
    //width: "100px",
    height: "50%",
  },
  signatureName: {
    marginLeft: "60%",
  },
  link: {
    textDecoration: "underline",
    fontSize: "20px",
    textAlign: "left",
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// Create Document Component
 const MyDocument = (props) => {
    //const data = props.pdf1[0];
  //console.log(props.noteData);
  //console.log(props.eventName);
  //console.log(props.noteData[0]);
   const myLists = props.noteData;  
  // const listItems = myLists.map((myList) => 
  //    //console.log(JSON.stringify(myList.name))  
  // ); 
//   console.log(listItems);
const [name, setName] = useState(); 
//console.log(listItems);
var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
// var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
// var d= date+' '+time;
var dateTime = today.toLocaleString();

// const t = new Date(myLists.map((myList) => 
//     (JSON.stringify(myList.createdAt))
// )
// );

function getDatePlease(D){
  return new Date(D).toLocaleString();
}




  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.header}>
          <Text style={styles.headerText}>ULTRA-X BD</Text>
        </View> */}
        
        {/* <View style={styles.header}>
          <Text style={styles.headerText2}>Note Information of VIPNOTE</Text>
        </View> */}
        <br />
        <View>
          {/* <Text style={styles.bodyText1}> Event Name: {props.eventName} </Text> */}
          <Text style={styles.bodyText2}>{`Date: `}{dateTime} </Text>
        </View>
        {/* <View style={styles.bodySection}>
          <Text style={styles.bodyText1}> Event Name: {props.eventName} </Text>
        </View> */}
        <View style={styles.noteSection}>
          <Text style={styles.noteText}> Note list of {props.eventName} </Text>
        </View>
        {/* <View style={styles.conclusionSection}>
          <Text
            style={styles.bodyText}
            
            >
              Note No: {"1"}
              </Text>
        </View> */}
        {/* <View style={styles.conclusionSection}>
          <Text
            style={styles.bodyText}
            
            >
              Event Name: {"Summer Event 1"}
              </Text>
        </View> */}
        
        {myLists.map((myList,index) =>
        <View style={styles.contentBorder}>
        <View style={styles.conclusionSection}>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>#:</Text>
        <Text style={styles.bodyTextUnbold}>{index+1}</Text>
        </view>
        <Text></Text>
          <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Note Title:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.title}</Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Company Name:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.company_id}</Text>
        </view>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Product Name:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.product_id}</Text>
        </view>
        <Text></Text>
        <view>
        <Text style={styles.bodyTextBold1}>Content:</Text>
        <Text style={styles.contentText1}>
               {"\n"+myList.content}
              {"\n"}
              </Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Additional Info:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.additional_info}</Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Created By:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.user_fullname}</Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Created At:</Text>
        <Text style={styles.bodyTextUnbold}>{getDatePlease(myList.createdAt)}</Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Updated By:</Text>
        <Text style={styles.bodyTextUnbold}>{myList.updated_by_user_fullname}</Text>
        </view>
        <Text></Text>
        <view style={styles.bodyTextBold}>
        <Text style={styles.bodyTextBold}>Updated At:</Text>
        <Text style={styles.bodyTextUnbold}>{getDatePlease(myList.updatedAt)}</Text>
        </view>
        <Text></Text>
          {/* <Text style={styles.bodyText}>
              #: {index+1}
              {"\n"}
              Note Title: {myList.title}
              {"\n"}
              Company Name: {myList.company_id}
              {"\n"}
              Product Name: {myList.product_id}
              {"\n"}
              Content:
              {"\n"}
              </Text>
              </View>
              <Text style={styles.contentText}>
               {"\n"+myList.content}
              {"\n"}
              </Text>
              <View style={styles.conclusionSection}>
              <Text
                style={styles.bodyText}
                >
              
              Additional Info: {myList.additional_info}
              {"\n"}
              Created By: {myList.user_fullname}
              {"\n"}
              Created At: {getDatePlease(myList.createdAt)}
              {"\n"}
              Updated By: {myList.updated_by_user_fullname}
              {"\n"}
              Updated At: {getDatePlease(myList.updatedAt)}
              {"\n"}
              
              <br />
              </Text> */}
        </View>
        </View>
        )}
        {/* <View style={styles.bodySection}>
          <Text
            style={styles.bodyTex}
            
            >
             
              EMAIL: {`${JSON.stringify(props.pdf1)}`}</Text>
        </View> */}
        
        
        
        {/* {props.pdf1.map((user) => (
            <View>
        <div>{user}</div>
        </View>
      ))} */}
      
        <br />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      </Page>
    </Document>
  );
};

export default MyDocument;