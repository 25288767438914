import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import "./modalStyle.css";

export const EditModalForm = (props) => {
  const [editedEventId, setEditedEventId] = useState("");
  const [editedEventName, setEditedEventName] = useState("");
  const [editedEventInfo, setEditedEventInfo] = useState("");
  const [editedEventCompanyList, setEditedEventCompanyList] = useState("");
  const [editedEventAdditionalInfo, setEditedEventAdditionalInfo] = useState("");
  const [editedEventStartedAt, setEditedEventStartedAt] = useState("");
  const [editedEventEndedAt, setEditedEventEndedAt] = useState("");
  const [editedEventStatus, setEditedEventStatus] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const handleChangeCompanyListConversion = (companyList) => {
    let companyListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < companyList.length; i++) {
        companyListArr.push(Object.values(companyList[i]).toString());
    }
    return companyListArr;
  }

  useEffect(() => {
    // console.log("inside UseEffect");
    setEditedEventId(props.rowDataForEdit.event_id);
    setEditedEventName(props.rowDataForEdit.name);
    setEditedEventInfo(props.rowDataForEdit.info);
    setEditedEventCompanyList(props.rowDataForEdit.company_list);
    setEditedEventAdditionalInfo(props.rowDataForEdit.additional_info);
    setEditedEventStartedAt(props.rowDataForEdit.startedAt);
    setEditedEventEndedAt(props.rowDataForEdit.endedAt);
    setEditedEventStatus(props.rowDataForEdit.expired);
    setCompanyList(handleChangeCompanyListConversion(props.companyList));
    
    // console.log(editedEventId);
    // console.log(editedEventName);
    // console.log(companyList);
  }, [props]);

  const onSelectCompanyList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedEventCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveCompanyList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedEventCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectStatus = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedEventStatus(
      selectedList.toString()
    );
    // console.log(editedEventStatus);
  };
  const onRemoveStatus = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedEventStatus(
      selectedList.toString()
    );
    // console.log(editedEventStatus);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // console.log(editedEventId);
    // console.log(editedEventName);
    // console.log(editedEventInfo);
    // console.log(editedEventCompanyList);
    const updatedEventData = {
      event_id: editedEventId,
      name: editedEventName,
      info: editedEventInfo,
      company_list: editedEventCompanyList,
      additional_info: editedEventAdditionalInfo,
      startedAt: editedEventStartedAt,
      endedAt: editedEventEndedAt,
      expired: editedEventStatus,
    }
    props.handleTableDataEdit(
      updatedEventData
      // editedProductId,
      // editedProductName,
      // editedProductUxSales,
      // editedProductUxtech
    );
    props.onHide();
  };
  // console.log(props.rowDataForEdit.name);
  // console.log(props.rowDataForEdit.product_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden", maxHeight: "fit-content", background: "202124"  }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Event Information
        </Modal.Title>
      </Modal.Header>
      {/* <SimpleBar forceVisible="y" autoHide={false}> */}
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
        <h4>Event ID: {props.rowDataForEdit.event_id}</h4>
        
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={`${editedEventName}`}
              onChange={(e) => setEditedEventName(e.target.value)}
              required
            />
            
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={`${editedEventInfo}`}
              onChange={(e) => setEditedEventInfo(e.target.value)}
              required
            />
            </Form.Group>

          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>CompanyList</Form.Label>
            <Multiselect
              selectedValues={`${props.rowDataForEdit.company_list}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveCompanyList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectCompanyList}
              options={companyList}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Additional Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={`${editedEventAdditionalInfo}`}
              onChange={(e) => setEditedEventAdditionalInfo(e.target.value)}
              required
            />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Start Time</Form.Label>
            <Form.Control
              type="datetime-local"
              // as="textarea"
              // rows={1}
              value={`${editedEventStartedAt}`}
              onChange={(e) => setEditedEventStartedAt(e.target.value)}
              required
            />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event End Time</Form.Label>
            <Form.Control
              type="datetime-local"
              // as="textarea"
              // rows={1}
              value={`${editedEventEndedAt}`}
              onChange={(e) => setEditedEventEndedAt(e.target.value)}
              required
            />
            </Form.Group>

          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Status</Form.Label>
            {/* <Form.Control
              value={`${editedProductUxtech}`}
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              selectedValues={`${props.rowDataForEdit.expired}`.split(", ")}
              // showCheckbox
              singleSelect={true}
              hidePlaceholder={false}
              closeOnSelect={true}
              avoidHighlightFirstOption={true}
              isObject={false}
              onRemove={onRemoveStatus}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectStatus}
              options={[
                "Upcoming",
                "Running",
                "Ended",
                "Delayed",   
              ]}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Save
          </Button>
        </Form>
       
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export const AddModalForm = (props) => {
  // const [editedProductId, setEditedProductId] = useState("");
  const [editedEventName, setEditedEventName] = useState("");
  const [editedEventInfo, setEditedEventInfo] = useState("");
  const [editedEventCompanyList, setEditedEventCompanyList] = useState("");
  const [editedEventAdditionalInfo, setEditedEventAdditionalInfo] = useState("");
  const [editedEventStartedAt, setEditedEventStartedAt] = useState("");
  const [editedEventEndedAt, setEditedEventEndedAt] = useState("");
  const [editedEventStatus, setEditedEventStatus] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const handleChangeCompanyListConversion = (companyList) => {
    let companyListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < companyList.length; i++) {
        companyListArr.push(Object.values(companyList[i]).toString());
    }
    return companyListArr;
  }


  useEffect(() => {
    // console.log("inside UseEffect");
    setCompanyList(handleChangeCompanyListConversion(props.companyList));
    
    // setEditedProductId(props.rowDataForEdit.product_id);
    // setEditedProductName(props.rowDataForEdit.name);
    // setEditedProductUxSales(props.rowDataForEdit.ux_sales);
    // setEditedProductUxtech(props.rowDataForEdit.ux_tech);
    // console.log(editedProductName);
    // console.log(editedProductUxSales);
  }, [props]);

  const onSelectCompanyList = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedEventCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveCompanyList = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedEventCompanyList(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectStatus = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedEventStatus(
      selectedList.toString()
    );
  };
  const onRemoveStatus = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedEventStatus(
      selectedList.toString()
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // // console.log(editedProductId);
    // console.log(editedEventName);
    // console.log(editedEventInfo);
    // console.log(editedEventCompanyList);
    const AddedEventData = {
     // event_id: editedEventId,
      name: editedEventName,
      //info: editedEventInfo,
      company_list: editedEventCompanyList,
      additional_info: editedEventAdditionalInfo,
      startedAt: editedEventStartedAt,
      endedAt: editedEventEndedAt,
      expired: editedEventStatus,
    }
    props.handleNewTableDataAdd(
      AddedEventData
      // editedProductName,
      // editedProductUxSales,
      // editedProductUxtech
    );
    props.onHide();
  };
  //console.log(props.rowDataForEdit.name);
  //console.log(props.rowDataForEdit.product_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden" , background: "202124" }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
        Add Event Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
        <h4>New Event Entry</h4>
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              //value={`${editedEventName}`}
              placeholder="Enter Event Name"
              onChange={(e) => setEditedEventName(e.target.value)}
              required
            />
            
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              //value={`${editedEventInfo}`}
              placeholder="Enter Info"
              onChange={(e) => setEditedEventInfo(e.target.value)}
              required
            />
            </Form.Group>

          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>CompanyList</Form.Label>
            <Multiselect
              //selectedValues={`${props.rowDataForEdit.company_list}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveCompanyList}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectCompanyList}
              options={companyList}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Additional Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              //value={`${editedEventAdditionalInfo}`}
              placeholder="Enter Additional Info"
              onChange={(e) => setEditedEventAdditionalInfo(e.target.value)}
              required
            />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event Start Time</Form.Label>
            <Form.Control
              type="datetime-local"
              // as="textarea"
              // rows={1}
              placeholder="Enter Start Time"
              value={`${editedEventStartedAt}`}
              onChange={(e) => setEditedEventStartedAt(e.target.value)}
              required
            />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Event End Time</Form.Label>
            <Form.Control
              type="datetime-local"
              // as="textarea"
              // rows={1}
              //value={`${editedEventEndedAt}`}
              placeholder="Enter End Time"
              onChange={(e) => setEditedEventEndedAt(e.target.value)}
              required
            />
            </Form.Group>

          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Status</Form.Label>
            {/* <Form.Control
              value={`${editedProductUxtech}`}
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              //selectedValues={`${props.rowDataForEdit.expired}`.split(", ")}
              // showCheckbox
              singleSelect={true}
              hidePlaceholder={false}
              closeOnSelect={true}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveStatus}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectStatus}
              options={[
                "Upcoming",
                "Running",
                "Ended",
                "Delayed",
                
              ]}
              style={{
                chips: {
                  background: "#4C4D4F",
                  color: "white"
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Add
          </Button>
        </Form>
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};
