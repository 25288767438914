import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
//import Brightness7Icon from "@material-ui/icons/Brightness7";
//import Brightness4Icon from "@material-ui/icons/Brightness4";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { MdBrightness2 } from "react-icons/md";
//import PrintIcon from "@material-ui/icons/Print";
import jsPDF from "jspdf";
import 'jspdf-autotable';
//import autoTable from 'jspdf-autotable';
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { MenuItem,Select } from '@mui/material';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';





import {
  HandleAllDataReadAPI,
  HandleDataCountAPI,
  HandleDeleteAPI,
  HandleEditAPI,
  HandleAddAPI,
} from "./HandleAPIFunctions";
import {
  ErrorMessage,
  WarningMessageForForAddRequiredData,
  EditSuccessMessage,
  DeleteSuccessMessage,
  AddSuccessMessage,
  Loading,
} from "./TableActionAlert";
import tableColumns from "./TableColumns";
import VIPNoteTable from "../VIPNoteTable/MaterialTable";
//import { CloseButton } from "react-bootstrap";
//import { breakpoints } from "@mui/system";



const HomeTable = (props) => {
  const [countData, setCountData] = useState(0);
  const [data, setDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  //const [filterSelectData, setFilterSelectData] = useState("");
  const [preferDarkMode, setPreferDarkMode] = useState(() => {
    const mode = localStorage.getItem("_tableDarkMode");
    return mode === "true" || false;
  });
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [lastUpdateId, setLastUpdateId] = useState(null);
  const [warningForAddRequiredData, setWarningForAddRequiredData] =
    useState(false);
    const [open, setOpen] = React.useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [eventID, setEventID] = useState();
  const [eventName, setEventName] = useState();
  //const [event_id, setEvent_id]= useState(4);
  //const [filter, setFilter] = useState(true);
  //const [filterData, setFilterData] = useState();
  const [expired, setExpired] = useState('all');
  //const [statusForFilter, setStatusForFilter] = useState("Upcoming");

  // console.log(data); 
  // console.log(filterData);
  

  useEffect(() => {
    handlecountData();
    handleClearMessage();
    handleTableDataShow();
    // setFilterData(data);
    //console.log(expired);
    //setFilterData(eventID===4?data:data.filter(dt=>dt.eventID===eventID));
    //setFilterData(event_id===4?data:data.filter(dt=>dt.event_id===event_id));
    setFilterData(expired==='all'?data:data.filter(dt=>dt.expired===expired));
    //console.log(filterData);
  }, [expired]);

  // useEffect(()=> {
  //   setFilterData(filterSelectData===2?data:data.filter(dt=>dt.filterSelectData===filterSelectData));
  //   console.log(filterData)
  // }, [filterSelectData]);

  // const changeFilterData = () => {
  //   setFilterData(statusForFilter==="All"?data:data.filter(dt=>dt.statusForFilter===statusForFilter));
  // }

  const theme = createTheme({
    palette: {
      type: preferDarkMode ? "dark" : "light",
    },
  });

  const handleDarkModeChange = () => {
    setPreferDarkMode((prev) => !prev);
    localStorage.setItem("_tableDarkMode", !preferDarkMode);
  };

  const handlecountData = () => {
    const ifSuccess = (countValue) => {
      setCountData(countValue);
    };
    const ifError = () => {
      setCountData(0);
    };
    HandleDataCountAPI(props.userAuthInfo, ifSuccess, ifError);
  };

  const handleTableDataShow = () => {
    setTableLoading(true);
    const ifSuccess = (allData) => {
      
      setDataList(allData);
      if(expired=="all"){
      setFilterData(allData);
      }
      setTableLoading(false);
    };
    const ifError = () => {
      setError(true);
    };
    HandleAllDataReadAPI(props.userAuthInfo, ifSuccess, ifError);
  };

  const handleNewTableDataAdd = (addData) => {
    handleClearMessage();
    const ifSuccess = (event_id) => {
      handleTableDataShow();
      setLastUpdateId(`${event_id}`);
      setAddSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };

    HandleAddAPI(props.userAuthInfo, addData, ifSuccess, ifError);
  };

  const handleTableDataEdit = (updatedData) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${updatedData.event_id}`);
      setEditSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    

    HandleEditAPI(props.userAuthInfo, updatedData, ifSuccess, ifError);
  };

  const handleTableDataDelete = (event_id) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${event_id}`);
      setDeleteSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    HandleDeleteAPI(props.userAuthInfo, `${event_id}`, ifSuccess, ifError);
  };

  const handleClearMessage = () => {
    setError(false);
    setWarningForAddRequiredData(false);
    setEditSuccess(false);
    setDeleteSuccess(false);
    setAddSuccess(false);
    setLastUpdateId(null);
  };

  const rowClickForNotes = (eventid,name) =>{
       setOpen(true);
       setEventID(eventid);
       setEventName(name);
       //console.log(eventID);
       //console.log(eventName);
       //return eventid;
  };
  const dummy =()=>{
    setOpen(true);

    var a=10;
    return a;
  };
  //const eventid1 = 14;
  
  const downloadpdf = ()=>{
    const doc = new jsPDF();

    doc.text("Event Details", 15, 10);
    doc.setFont("Helvertica","Normal");
    doc.text("eventName", 100,10);
    // doc.autoTable({
    //   columns:tableColumns.map(col=>({...col,dataKey:col.field})),
    //   body:data
      
    // })
    doc.save("EventTable.pdf");
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1600,
    ['@media (max-width:1920px)']: { 
      width: 1000
    },
    bgcolor: '#202124',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const paginationArrayMakerFun = () => {
    let paginationArrayMaker = [];
    for(let i =5; i< data.length ; i=i+5){
      paginationArrayMaker.push(i);
    }
    paginationArrayMaker.push(data.length);
    // setPaginationArray(paginationArrayMaker);
    //  setTableLoading(false);
    // console.log(paginationArray);
    return paginationArrayMaker;
  }

  return (
    <>
      <ErrorMessage error={error} handleClearMessage={handleClearMessage} />
      <WarningMessageForForAddRequiredData
        error={warningForAddRequiredData}
        handleClearMessage={handleClearMessage}
      />
      <EditSuccessMessage
        editSuccess={editSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <DeleteSuccessMessage
        deleteSuccess={deleteSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <AddSuccessMessage
        addSuccess={addSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />

      {tableLoading && <Loading tableLoading={tableLoading} />}
      {!tableLoading && (
        <MuiThemeProvider theme={theme}>
          
          <MaterialTable
            title={
              <h4 style={{ fontSize: "1.2rem" }}>Total Entries: {countData}</h4>
            }
            style={{
              width: "100%",
              // width: "100%",
              // maxWidth: "90% !important",
              display: "inline-table",
              // display: "inline-block",
              height: "90%",
              borderRadius: "2rem",
              // fontSize: "0.7rem",
              fontSize: "inherit",
              // paddingLeft: "0px",
              // paddingRight: "0px",
            }}
            data={filterData}
            columns={tableColumns}
            // detailPanel={[
            //   {
            //     tooltip: "Show body",
            //     render: (rowData) => {
            //       return (
            //         <div
            //           style={{
            //             margin: "20px",
            //             fontSize: 20,
            //             textAlign: "center",
            //             color: "black",
            //             backgroundColor: "white",
            //           }}
            //         >
            //           {rowData.content}
            //         </div>
            //       );
            //     },
            //   },
            // ]}
            onRowClick={(evt,rowData)=>{rowClickForNotes(rowData.event_id,rowData.name)}}
            //onRowClick={(evt,rowData)=>{dummy()}}
            // editable={{
            //   onRowAdd: (newRow) =>
            //     new Promise((res, rej) => {
            //       console.log(newRow);
            //       if (
            //         newRow.name &&
            //         newRow.company_list &&
            //         newRow.additional_info &&
            //         newRow.startedAt &&
            //         newRow.endedAt &&
            //         newRow.expired
            //       ) {
            //         const addData = {
            //           name: newRow.name,
            //           company_list: newRow.company_list,
            //           additional_info: newRow.additional_info,
            //           startedAt: newRow.startedAt,
            //           endedAt: newRow.endedAt,
            //           expired: newRow.expired,
            //         };
            //         handleNewTableDataAdd(addData);
            //         res();
            //       } else {
            //         setWarningForAddRequiredData(true);
            //         rej();
            //       }
            //     }),
            //   onRowDelete: (selectedRow) =>
            //     new Promise((res, rej) => {
            //       console.log(selectedRow.event_id);
            //       handleTableDataDelete(selectedRow.event_id);
            //       res();
            //     }),
            //   onRowUpdate: (updatedRow, oldRow) =>
            //     new Promise((res, rej) => {
            //       console.log(updatedRow);
            //       handleTableDataEdit(updatedRow);
            //       res();
            //     }),
            // }}
            options={{
              pageSize: data.length,
              pageSizeOptions: paginationArrayMakerFun(),
              
              actionsColumnIndex: -1,
              addRowPosition: "first",
              //selection: true,
              //filtering: true,
              // paging: false,
              headerStyle: {
                position: "sticky",
                top: "0",
                fontSize: "1.1rem",
                fontWeight: "500",
                width: "0rem",
                paddingLeft: "0px",
                paddingRight: "0px",
                //backgroundColor: "#039be5",
                backgroundColor: "rgb(77, 121, 255)",
              },
              maxBodyHeight: "60vh",
            }}
            actions={[
              // {
              //   hidden: true,
              //   tooltip: "Remove All Selected Users",
              //   icon: "delete",
              //   onClick: (evt, data) => {
              //     alert("You want to delete " + data.length + " rows");
              //     console.log(data);
              //   },
              // },
              // {
              //   icon: () =><ArrowCircleDownIcon/>,
              //   tooltip: "Export to PDF",
              //   onClick: ()=> downloadpdf(),
              //   isFreeAction: true,
              // },
              {
                icon: () =><Select
                className="dropdownForStatus"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                style={{width:150,height:35}}
                value={expired}
                 onChange={(e)=>setExpired(e.target.value)}
                // label="Status"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'Upcoming'}>Upcoming</MenuItem>
                <MenuItem value={'Running'}>Running</MenuItem>
                <MenuItem value={'Delayed'}>Delayed</MenuItem>
                <MenuItem value={'Completed'}>Completed</MenuItem>
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={4}>4</MenuItem> */}

              </Select>,
              //tooltip: "Filter by Status",
                isFreeAction: true,
              },
              {
                icon: () =>
                  //preferDarkMode ? <Brightness7Icon /> : <Brightness4Icon />,
                  preferDarkMode ? (
                    <BsFillBrightnessHighFill className="tableIcon"/>
                  ) : (
                    <MdBrightness2 className="tableIcon"/>
                  ),
                tooltip: "Toggle Light/Dark Mode",
                onClick: handleDarkModeChange,
                isFreeAction: true,
              },
            ]}
            
            
          />
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        
        
        {/* <MaterialTable
            title={
              <h4 style={{ fontSize: "1.2rem" }}>Total Entries: {countData}</h4>
            }
            style={{
              width: "70%",
              // width: "100%",
              // maxWidth: "90% !important",
              display: "inline-table",
              // display: "inline-block",
              height: "60%",
              marginTop:"80px",
              marginLeft:"15%",
              marginRight:"15%",
              borderRadius: "2rem",
              // fontSize: "0.7rem",
              fontSize: "inherit",
              // paddingLeft: "0px",
              // paddingRight: "0px",
            }}
            data={data}
            columns={tableColumns}
          />*/}
          
        <Box sx={style}>
        {/* <button className="homeModalButton" onClick={handleClose}>X</button> */}
        <IconButton sx={{ bgcolor: 'background.paper' ,color:'black'}} className="homeModalButton" onClick={handleClose}>
            <CloseIcon />
        </IconButton>
          <h1 className="notePopUpTitle">Note Info of {eventName}</h1>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Note Table
          </Typography> */}
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}
          <VIPNoteTable userAuthInfo={props.userAuthInfo}  eventid1={eventID} eventName={eventName}/>
          {/* </Typography> */}
        </Box>
        
      </Modal>
        </MuiThemeProvider>
      )}
    </>
  );
};

export default HomeTable;
