import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
// import Brightness4Icon from "@material-ui/icons/Brightness4";
// import Brightness7Icon from "@material-ui/icons/Brightness7";
import {FiEdit } from "react-icons/fi";
import { GoDiffAdded } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { MdBrightness2 } from "react-icons/md";
import DownArrow from './DownArrow.png';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import MaterialTable from "material-table";
import React, { forwardRef,useEffect, useState } from "react";
import {
  HandleAddAPI, HandleAllDataReadAPI,
  HandleDeleteAPI,
  HandleEditAPI, handleGetCompanyListAPI, handleGetProductListAPI
} from "./HandleAPIFunctions";
import { AddModalForm, EditModalForm } from "./PopUpModalForm";
import {
  AddSuccessMessage, DeleteSuccessMessage, EditSuccessMessage, ErrorMessage, Loading, WarningMessageForForAddRequiredData
} from "./TableActionAlert";
import setTableColumns from "./TableColumns";
import MyDocument from "./GeneratePdf";
import {
  // Page,
  // Text,
  // View,
  // Document,
  // StyleSheet,
  // Image,
  // Link,
  // PDFViewer,ReactPDF,
   PDFDownloadLink
} from "@react-pdf/renderer";



const VIPNoteTable = (props) => {
  //console.log(props.userAuthInfo);
  //console.log(props.eventName);
  // const [countData, setCountData] = useState(0);
  const [data, setDataList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [productList, setProductList] = useState([]);
  //const [productIdList, setProductIdList] = useState({});
  //const [eventIdList, setEventIdList] = useState({});
  //const [companyIdList, setCompanyIdList] = useState({});
  const [preferDarkMode, setPreferDarkMode] = useState(() => {
    const mode = localStorage.getItem("_tableDarkMode");
    return mode === "true" || false;
  });
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [lastUpdateId, setLastUpdateId] = useState(null);
  const [warningForAddRequiredData, setWarningForAddRequiredData] =
    useState(false);
  const [editModalFormShow, SetEditModalFormShow] = useState(false);
  const [rowDataForEdit, SetRowDataForEdit] = useState({});
  const [addModalFormShow, SetAddModalFormShow] = useState(false);

  useEffect(() => {
    // handlecountData();
    handleClearMessage();
    //handleGetProductIdList();
   // handleGetEventIdList();
   // handleGetCompanyIdList();
    handleTableDataShow();
    handleGetCompanyList();
    handleGetProductList();
  }, []);

  const theme = createTheme({
    palette: {
      type: preferDarkMode ? "dark" : "light",
    },
  });

  const handleDarkModeChange = () => {
    setPreferDarkMode((prev) => !prev);
    localStorage.setItem("_tableDarkMode", !preferDarkMode);
  };

  //Getting the Company List Starts
  const handleGetCompanyList = () => {
    const ifSuccess = (companyList) => {
      let companyListStringToArr = companyList.split(',');
      setCompanyList(companyListStringToArr);
      //console.log("data INNNNNN")
      //console.log(companyListStringToArr);
      //console.log("data OUTTTTT")
    };
    handleGetCompanyListAPI(props.userAuthInfo, props.eventid1, ifSuccess)

  }
  //Getting the Company List Ends


  //Getting the Product List Starts
  const handleGetProductList = () => {
    const ifSuccess = (productList) => {
      setProductList(productList);
      //console.log("data INNNNNN")
      //console.log(productList);
      //console.log("data OUTTTTT")
    };
    handleGetProductListAPI(props.userAuthInfo, ifSuccess)

  }
  //Getting the Product List Ends

  // const handlecountData = () => {
  //   const ifSuccess = (countValue) => {
  //     setCountData(countValue);
  //   };
  //   const ifError = () => {
  //     setCountData(0);
  //   };
  //   HandleDataCountAPI(props.userAuthInfo, ifSuccess, ifError);
  // };

  const handleTableDataShow = () => {
    setTableLoading(true);
    const ifSuccess = (allData) => {
      setDataList(allData);
      setTableLoading(false);
      //console.log("Note Data");
      //console.log(data);
    };
    const ifError = () => {
      setError(true);
    };
    HandleAllDataReadAPI(props.userAuthInfo,props.eventid1, ifSuccess, ifError);
  };

  // const convertArrayForLookupInTable = (arr) => {
  //   let convertedObj = {};
  //   arr.forEach((f, i) => {
  //     Object.keys(f).forEach(function eachKey(key) {
  //       convertedObj[`${key}`] = `${f[key]}`;
  //     });
  //   });
  //   console.log(convertedObj);
  //   return convertedObj;
  // };

  // const handleGetCompanyIdList = () => {
  //   setTableLoading(true);
  //   const ifSuccess = (idList) => {
  //     const forLookUpInTable = convertArrayForLookupInTable(idList);
  //     setCompanyIdList(forLookUpInTable);
  //     console.log(companyIdList);
  //   };
  //   const ifError = () => {
  //     setError(true);
  //   };
  //   HandleGetCompanyIdListAPI(props.userAuthInfo, ifSuccess, ifError);
  // };

  // const handleGetEventIdList = () => {
  //   setTableLoading(true);
  //   const ifSuccess = (idList) => {
  //     const forLookUpInTable = convertArrayForLookupInTable(idList);
  //     setEventIdList(forLookUpInTable);
  //     console.log(eventIdList);
  //   };
  //   const ifError = () => {
  //     setError(true);
  //   };
  //   HandleGetEventIdListAPI(props.userAuthInfo, ifSuccess, ifError);
  // };

  // const handleGetProductIdList = () => {
  //   setTableLoading(true);
  //   const ifSuccess = (idList) => {
  //     const forLookUpInTable = convertArrayForLookupInTable(idList);
  //     setProductIdList(forLookUpInTable);
  //     console.log(productIdList);
  //   };
  //   const ifError = () => {
  //     setError(true);
  //   };
  //   HandleGetProductIdListAPI(props.userAuthInfo, ifSuccess, ifError);
  // };

  const handleNewTableDataAdd = (
    title,
    event_id,
    company_id,
    product_id,
    content,
    additional_info
  ) => {
    handleClearMessage();
    const ifSuccess = (note_id) => {
      handleTableDataShow();
      setLastUpdateId(`${note_id}`);
      setAddSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    const addData = {
      title,
      event_id,
      company_id,
      product_id,
      content,
      additional_info,
    };
    HandleAddAPI(props.userAuthInfo, addData, ifSuccess, ifError);
  };

  const handleTableDataEdit = (
    note_id,
    title,
    event_id,
    company_id,
    product_id,
    content,
    additional_info
  ) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${note_id}`);
      setEditSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    const editData = {
      note_id,
      title,
      event_id,
      company_id,
      product_id,
      content,
      additional_info,
    };

    HandleEditAPI(props.userAuthInfo, editData, ifSuccess, ifError);
  };

  const handleTableDataDelete = (note_id) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${note_id}`);
      setDeleteSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    HandleDeleteAPI(props.userAuthInfo, `${note_id}`, ifSuccess, ifError);
  };

  const handleClearMessage = () => {
    setError(false);
    setWarningForAddRequiredData(false);
    setEditSuccess(false);
    setDeleteSuccess(false);
    setAddSuccess(false);
    setLastUpdateId(null);
  };

  const handleHideEditModal = () => {
    SetEditModalFormShow(false);
    SetRowDataForEdit({});
  }

  const downloadpdf = ()=>{
    const doc = new jsPDF();

    doc.text("Note Details", 15, 10);
    doc.autoTable({
      columns:setTableColumns().map(col=>({...col,dataKey:col.field})),
      body:data
      
    })
    doc.save("NoteTable.pdf");
  };


  const tableIcons = {
    Delete: forwardRef((props, ref) => <MdDeleteOutline {...props} ref={ref} className="tableIcon"/>),
    // edit: forwardRef((props, ref) => <FiEdit {...props} ref={ref} className="tableIcon"/>),
  }
  const tableRef = React.createRef();
  const paginationArrayMakerFun = () => {
    let paginationArrayMaker = [];
    for(let i =5; i< data.length ; i=i+5){
      paginationArrayMaker.push(i);
    }
    paginationArrayMaker.push(data.length);
    // setPaginationArray(paginationArrayMaker);
    //  setTableLoading(false);
    // console.log(paginationArray);
    return paginationArrayMaker;
  }

  const convertDateToLocalDate = (D) => {
    return new Date(D).toLocaleString();
  }

  return (
    <>
      <ErrorMessage error={error} handleClearMessage={handleClearMessage} />
      <WarningMessageForForAddRequiredData
        error={warningForAddRequiredData}
        handleClearMessage={handleClearMessage}
      />
      <EditSuccessMessage
        editSuccess={editSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <DeleteSuccessMessage
        deleteSuccess={deleteSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <AddSuccessMessage
        addSuccess={addSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />

      <EditModalForm
        eventName={props.eventName}
        companyList={companyList}
        productList={productList}
        rowDataForEdit={rowDataForEdit}
        handleTableDataEdit={handleTableDataEdit}
        show={editModalFormShow}
        //onHide={() => SetEditModalFormShow(false)}
        onHide={handleHideEditModal}
      />
      <AddModalForm
        eventName={props.eventName}
        companyList={companyList}
        productList={productList}
        addForEventId={props.eventid1}
        handleNewTableDataAdd={handleNewTableDataAdd}
        show={addModalFormShow}
        onHide={() => SetAddModalFormShow(false)}
      />

      {tableLoading && <Loading tableLoading={tableLoading} />}
      {!tableLoading && (
        <MuiThemeProvider theme={theme}>
          <MaterialTable
           tableRef={tableRef}
           icons={tableIcons}
            title={
              // <h4 style={{ fontSize: "1.2rem" }}>Total Entries: {countData}</h4>
              <h4 style={{ fontSize: "1.2rem" }}>
                Total Entries: {data.length}
              </h4>
            }
            style={{
              width: "100%",
              // width: "100%",
              // maxWidth: "90% !important",
              display: "inline-table",
              // display: "inline-block",
              height: "90%",
              borderRadius: "2rem",
              // fontSize: "0.7rem",
              fontSize: "inherit",
              // paddingLeft: "0px",
              // paddingRight: "0px",
            }}
            data={data}
            columns={setTableColumns()}
            // detailPanel={[
            //   {
            //     tooltip: "Show body",
            //     render: (rowData) => {
            //       return (
            //         <div
            //           style={{
            //              margin: "20px",
            //             fontSize: 20,
            //             textAlign: "center",
            //             color: "black",
            //             backgroundColor: "white",
            //           }}
            //         >
            //           {rowData.content}
            //         </div>
            //       );
            //     },
            //   },
            // ]}
            detailPanel={[
              {
                tooltip: "Show Details",
                render: (rowData) => {
                  return (
                    <div className="custom-detail-panel-wrapper">
                      <div>
                        <img
                          src={DownArrow}
                          alt="Logo"
                          width="150px"
                          height="70px"
                          style= {{opacity: "0.5"}}
                          className="downArrowImg"
                        />
                        </div>
                      <div className="custom-detail-panel">
                        <div className="custom-detail-panel-note_id">
                          <strong>Note id:</strong> {rowData.note_id}
                        </div>
                        <div className="custom-detail-panel-user_fullname">
                          <strong>Created By: </strong>
                          <br /> {rowData.user_fullname}
                        </div>
                        <div className="custom-detail-panel-createdAt">
                          <strong>Created At: </strong>
                          <br />
                          {convertDateToLocalDate(rowData.createdAt)} 
                          {/* {rowData.createdAt} */}
                        </div>
                        <div className="custom-detail-panel-updated_by_user_fullname">
                          <strong>last Updated By:</strong>
                          <br />
                          {rowData.updated_by_user_fullname}
                        </div>
                        <div className="custom-detail-panel-updatedAt">
                          <strong>last Updated At:</strong>
                          <br />
                          {/* {rowData.updatedAt} */}
                          {convertDateToLocalDate(rowData.updatedAt)} 
                        </div>

                      </div>
                      <div>
                        <img
                          src={DownArrow}
                          alt="Logo"
                          width="150px"
                          height="70px"
                          style= {{opacity: "0.5"}}
                          className="downArrowImg"
                        />
                        </div>
                    </div>
                  );
                },
              },
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            editable={{
              // onRowAdd: (newRow) =>
              //   new Promise((res, rej) => {
              //     console.log(newRow);
              //     if (
              //       newRow.title &&
              //       newRow.event_id &&
              //       newRow.company_id &&
              //       newRow.product_id &&
              //       newRow.content &&
              //       newRow.additional_info
              //     ) {
              //       handleNewTableDataAdd(
              //         newRow.title,
              //         newRow.event_id,
              //         newRow.company_id,
              //         newRow.product_id,
              //         newRow.content,
              //         newRow.additional_info
              //       );
              //       res();
              //     } else {
              //       setWarningForAddRequiredData(true);
              //       rej();
              //     }
              //   }),
              onRowDelete: (selectedRow) =>
                new Promise((res, rej) => {
                  // console.log(selectedRow.note_id);
                  handleTableDataDelete(selectedRow.note_id);
                  res();
                }),
              // onRowUpdate: (updatedRow, oldRow) =>
              //   new Promise((res, rej) => {
              //     console.log(updatedRow);
              //     handleTableDataEdit(
              //       updatedRow.note_id,
              //       updatedRow.title,
              //       updatedRow.event_id,
              //       updatedRow.company_id,
              //       updatedRow.product_id,
              //       updatedRow.content,
              //       updatedRow.additional_info
              //     );
              //     res();
              //   }),
            }}
            options={{
              pageSize: data.length,
              pageSizeOptions: paginationArrayMakerFun(),
              actionsColumnIndex: -1,
              addRowPosition: "first",
              //selection: true,
              // paging: false,
              headerStyle: {
                position: "sticky",
                top: "0",
                fontSize: "1.1rem",
                fontWeight: "900",
                width: "0rem",
                paddingLeft: "0px",
                paddingRight: "0px",
                //backgroundColor: "rgb(64, 224, 208, .9)",
                //border: "1px solid black",
                backgroundColor: "rgb(77, 121, 255)",
                height: "5rem"
              },
              maxBodyHeight: "60vh",
            }}
            actions={[
              {
                icon: () => <GoDiffAdded className="tableIcon"/>,
                tooltip: "Add User",
                isFreeAction: true,
                onClick: (event) => {
                  SetAddModalFormShow(true);
                },
              },
              (rowData) => ({
                icon: () => <FiEdit className="tableIcon"/>,
                tooltip: "Edit",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  SetRowDataForEdit(rowData);
                  SetEditModalFormShow(true);
                  // console.log(rowData);
                  //Do whatever you want with the row clicked data.
                },
              }),
              // {
              //   hidden: true,
              //   tooltip: "Remove All Selected Users",
              //   icon: "delete",
              //   onClick: (evt, data) => {
              //     alert("You want to delete " + data.length + " rows");
              //     console.log(data);
              //   },
              // },
              // {
              //   icon: () => <ArrowCircleDownIcon />,
              //   tooltip: "Export to PDF",
              //   onClick: () => downloadpdf(),
              //   isFreeAction: true,
              // },
              {
                icon: () =><PDFDownloadLink
               
                document={
                  <MyDocument eventName={props.eventName} noteData={data}/>
                }
                fileName={`NoteTable_of_${props.eventName}.pdf`}
                
              >
                {({ blob, url, loading, error }) =>
                  loading ? ".." : <ArrowCircleDownIcon />
                }
              </PDFDownloadLink>,
                tooltip: "Export to PDF",
                //onClick: () => downloadpdf(),
                isFreeAction: true,
              },

              {
                icon: () =>
                  // preferDarkMode ? <Brightness7Icon /> : <Brightness4Icon />,
                  preferDarkMode ? (
                    <BsFillBrightnessHighFill className="tableIcon"/>
                  ) : (
                    <MdBrightness2 className="tableIcon"/>
                  ),
                tooltip: "Toggle Light/Dark Mode",
                onClick: handleDarkModeChange,
                isFreeAction: true,
              },
            ]}
          />
        </MuiThemeProvider>
      )}
    </>
  );
};

export default VIPNoteTable;
