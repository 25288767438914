import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import "./modalStyle.css";

export const EditModalForm = (props) => {
  const [editedUserId, setEditedUserId] =useState("");
  const [editedNewUserName, setEditedNewUserName] =useState("");
  const [editedUserLevel, setEditedUserLevel] =useState("");
  const [editedUserFullName, setEditedUserFullName] =useState("");
  const [editedUserEmail, setEditedUserEmail] =useState("");
  const [editedUserDepartment, setEditedUserDepartment] =useState("");
  const [editedUserPasswordChanged, setEditedUserPasswordChanged] =
    useState("");
   // const [selectedValuesForUserLevel, setSelectedValuesForUserLevel] = useState("");

//   const handleChangeUserListConversion = (userList) => {
//   let userListArr = [];
//     // userListArr.push(Object.values(data[0]));
//     // userListArr.push(Object.values(data[1]));
//     // console.log(userListArr[]);
//     for(let i = 0; i < userList.length; i++) {
//     userListArr.push(Object.values(userList[i]).toString());
//   }
//   return userListArr;
// }

  useEffect(() => {
    // console.log("inside UseEffect");
    setEditedUserId(props.rowDataForEdit.user_id);
    setEditedNewUserName(props.rowDataForEdit.username);
    setEditedUserLevel(props.rowDataForEdit.user_level);
    setEditedUserFullName(props.rowDataForEdit.fullname);
    setEditedUserEmail(props.rowDataForEdit.email);
    setEditedUserDepartment(props.rowDataForEdit.department);
    setEditedUserPasswordChanged(props.rowDataForEdit.passwordChanged);
    //setSelectedValuesForUserLevel()
    // if(editedUserPasswordChanged == "0"){
    //   setSelectedValuesForUserLevel("Default")

    // }
    // else if(editedUserPasswordChanged == "1"){
    //   setSelectedValuesForUserLevel("User Password")

    // }
    // if(props.rowDataForEdit.passwordChanged == 0){
    //   setEditedUserPasswordChanged("Default")
    // }
    // else if(props.rowDataForEdit.passwordChanged == 1){
    //   setEditedUserPasswordChanged("User Password")
    // }
    //setEditedUserPasswordChanged(props.rowDataForEdit.passwordChanged)
    //setUserList(handleChangeUserListConversion(props.userList));
    // console.log(editedNewUserName);
    // console.log(editedUserEmail);
  }, [props]);

  // const onSelectUxSales = (selectedList, selectedItem) => {
  //   console.log(selectedList);
  //   setEditedProductUxSales(
  //     selectedList.toString().replace(/,(?=[^\s])/g, ", ")
  //   );
  // };
  // const onRemoveUxSales = (selectedList, removedItem) => {
  //   console.log(selectedList);
  //   setEditedProductUxSales(
  //     selectedList.toString().replace(/,(?=[^\s])/g, ", ")
  //   );
  // };
  const onSelectUserLevel = (selectedList, selectedItem) => {
    // console.log(selectedList);
    if(selectedList[0] == "Admin"){
      // console.log("1");
      setEditedUserLevel("1")
    }
    else if(selectedList[0] == "User"){
      // console.log("0");
      setEditedUserLevel("0")
    }
    //setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onRemoveUserLevel = (selectedList, removedItem) => {
    // console.log(selectedList);
    if(selectedList[0] == "Admin"){
      // console.log("1");
      setEditedUserLevel("1")
    }
    else if(selectedList[0] == "User"){
      // console.log("0");
      setEditedUserLevel("0")
    }
    //setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onSelectPasswordChanged = (selectedList, selectedItem) => {
    // console.log(selectedList);
    if(selectedList[0] == "Default"){
      setEditedUserPasswordChanged("0")
      // console.log(0)
    }
    else if(selectedList[0] == "User Password"){
      setEditedUserPasswordChanged("1")
      // console.log(1);
    }
    //setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onRemovePasswordChanged = (selectedList, removedItem) => {
    // console.log(selectedList);
    if(selectedList[0] == "Default"){
      setEditedUserPasswordChanged("0")
      // console.log(0)
    }
    else if(selectedList[0] == "Updated"){
      setEditedUserPasswordChanged("1")
      // console.log(1);
    }
    //setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // console.log(editedUserId);
    // console.log(editedNewUserName);
    // console.log(editedUserLevel);
    // console.log(editedUserFullName);
    let editData = {
      user_id: editedUserId,
      user_level: editedUserLevel,
      fullname: editedUserFullName ,
      email: editedUserEmail ,
      department: editedUserDepartment,
    };
    props.handleTableDataEdit(editData);
    if (editedUserPasswordChanged === "0"){
      props.HandlePasswordReset(editedUserId);
    }
    props.onHide();
  };
  // console.log(props.rowDataForEdit.editedUserId);
  // console.log(props.rowDataForEdit.editedUserPasswordChanged);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "535px",
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        overflow: "hidden",
        maxHeight: "fit-content",
        background: "202124",
      }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit User Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
        <Modal.Body
          style={{
            background: "#202124",
            color: "white",
            maxHeight: "fit-content",
          }}
        >
          <h4>User Name: {props.rowDataForEdit.username}</h4>
          <Form
            style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
            onSubmit={onSubmit}
          >
            {/* <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                value={`${editedNewUserName}`}
                onChange={(e) => setEditedNewUserName(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            {/* </Form.Group> */} 
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Full name</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                value={`${editedUserFullName}`}
                onChange={(e) => setEditedUserFullName(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Email</Form.Label>
              <Form.Control
                type="email"
                //placeholder="Enter email"
                value={`${editedUserEmail}`}
                onChange={(e) => setEditedUserEmail(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Department</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                value={`${editedUserDepartment}`}
                onChange={(e) => setEditedUserDepartment(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Level</Form.Label>
              <Multiselect
                selectedValues={`${props.rowDataForEdit.user_level}` == "1" ? ["Admin"] : ["User"]}
                //selectedValues={[selectedValuesForUserLevel]}
                showCheckbox
                hidePlaceholder={false}
                singleSelect={true}
                closeOnSelect={true}
                isObject={false}
                avoidHighlightFirstOption={true}
                onRemove={onRemoveUserLevel}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectUserLevel}
                //options={[{name: 'Optionasd', id: 100},{name: 'Optioasdasd', id: 200}]}
                options={["Admin", "User"]}
                style={{
                  chips: {
                    // background: "#0B5ED7",
                    background: "#4C4D4F",
                    color: "white"
                  },
                  multiselectContainer: {
                    color: "black",
                  },
                  searchBox: {
                    display: "absolute",
                    border: "none",
                    "border-bottom": "1px solid blue",
                    "border-radius": "0px",
                    background: "white",
                  },
                  // optionContainer: { // To change css for option container
                  //   border: "2px solid",
                  //   display: "absolute",
                  //   zIndex: "800"
                  //   },
                  //   option: { // To change css for dropdown options
                  //   color: "blue"
                  //   }
                }}
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Password Status</Form.Label>
              <Multiselect
                // selectedValues={`${props.rowDataForEdit.passwordChanged}`.split(
                //   ", "
                // )}
                selectedValues={`${props.rowDataForEdit.passwordChanged}` == "1" ? ["Updated"] : ["Default"]}
                //selectedValues={`${userList}`.split(",")}
                showCheckbox
                hidePlaceholder={false}
                singleSelect={true}
                closeOnSelect={true}
                isObject={false}
                avoidHighlightFirstOption={true}
                onRemove={onRemovePasswordChanged}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectPasswordChanged}
                //options={["0", "1"]}
                options={["Default"]}
                style={{
                  chips: {
                    // background: "#0B5ED7",
                    background: "#4C4D4F",
                    color: "white"
                  },
                  multiselectContainer: {
                    color: "black",
                  },
                  searchBox: {
                    display: "absolute",
                    border: "none",
                    "border-bottom": "1px solid blue",
                    "border-radius": "0px",
                    background: "white",
                  },
                  // optionContainer: { // To change css for option container
                  //   border: "2px solid",
                  //   display: "absolute",
                  //   zIndex: "800"
                  //   },
                  //   option: { // To change css for dropdown options
                  //   color: "blue"
                  //   }
                }}
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "1rem", marginLeft: "40%" }}
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export const AddModalForm = (props) => {
  // const [editedProductId, setEditedProductId] = useState("");
  //const [editedUserId, setEditedUserId] = useState("");
  const [editedNewUserName, setEditedNewUserName] = useState("");
  const [editedUserLevel, setEditedUserLevel] = useState("");
  const [editedUserFullName, setEditedUserFullName] = useState("");
  const [editedUserEmail, setEditedUserEmail] = useState("");
  const [editedUserDepartment, setEditedUserDepartment] = useState("");
  const [editedUserPasswordChanged, setEditedUserPasswordChanged] =
    useState("");

//   const handleChangeUserListConversion = (userList) => {
//   let userListArr = [];
//     // userListArr.push(Object.values(data[0]));
//     // userListArr.push(Object.values(data[1]));
//     // console.log(userListArr[]);
//     for(let i = 0; i < userList.length; i++) {
//     userListArr.push(Object.values(userList[i]).toString());
//   }
//   return userListArr;
// }

  useEffect(() => {
    //setUserList(handleChangeUserListConversion(props.userList));
    // console.log("inside UseEffect");
    // setEditedProductId(props.rowDataForEdit.product_id);
    // setEditedProductName(props.rowDataForEdit.name);
    // setEditedProductUxSales(props.rowDataForEdit.ux_sales);
    // setEditedProductUxtech(props.rowDataForEdit.ux_tech);
    // console.log(editedProductName);
    // console.log(editedProductUxSales);
  }, [props]);

  const onSelectUserLevel = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onRemoveUserLevel = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onSelectPasswordChanged = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  const onRemovePasswordChanged = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedUserLevel(selectedList.toString().replace(/,(?=[^\s])/g, ", "));
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    let addData = {
      name: editedNewUserName,
      user_level:  editedUserLevel,
      fullname: editedUserFullName,
      email: editedUserEmail,
      department: editedUserDepartment,
    };
    props.handleNewTableDataAdd(addData);
    props.onHide();
  };
  //console.log(props.rowDataForEdit.name);
  //console.log(props.rowDataForEdit.product_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        width: "535px",
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        overflow: "hidden",
        maxHeight: "fit-content",
        background: "202124",
      }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit User Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
        <Modal.Body
          style={{
            background: "#202124",
            color: "white",
            maxHeight: "fit-content",
          }}
        >
          {/* <h4>Product ID: {props.rowDataForEdit.user_id}</h4> */}
          <Form
            style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
            onSubmit={onSubmit}
          >
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                // value={`${editedNewUserName}`}
                placeholder="Enter UserName"
                onChange={(e) => setEditedNewUserName(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Fullname</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                //value={`${editedUserFullName}`}
                placeholder="Enter User Full Name"
                onChange={(e) => setEditedUserFullName(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Email</Form.Label>
              <Form.Control
                type="email"
                //placeholder="Enter email"
                //value={`${editedUserEmail}`}
                placeholder="Enter User Email"
                onChange={(e) => setEditedUserEmail(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Department</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                //value={`${editedUserDepartment}`}
                placeholder="Enter Department"
                onChange={(e) => setEditedUserDepartment(e.target.value)}
                required
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>User Level</Form.Label>
              <Multiselect
                // selectedValues={`${props.rowDataForEdit.user_level}`.split(
                //   ", "
                // )}
                //selectedValues={`${userList}`.split(",")}
                showCheckbox
                hidePlaceholder={false}
                singleSelect={true}
                closeOnSelect={true}
                isObject={false}
                avoidHighlightFirstOption={true}
                onRemove={onRemoveUserLevel}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectUserLevel}
                options={["Admin", "User"]}
                style={{
                  chips: {
                    // background: "#0B5ED7",
                    background: "#4C4D4F",
                    color: "white"
                  },
                  multiselectContainer: {
                    color: "black",
                  },
                  searchBox: {
                    display: "absolute",
                    border: "none",
                    "border-bottom": "1px solid blue",
                    "border-radius": "0px",
                    background: "white",
                  },
                  // optionContainer: { // To change css for option container
                  //   border: "2px solid",
                  //   display: "absolute",
                  //   zIndex: "800"
                  //   },
                  //   option: { // To change css for dropdown options
                  //   color: "blue"
                  //   }
                }}
              />
              {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
             </Form.Group>
            
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "1rem", marginLeft: "40%" }}
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};
