import "./WelcomeMessage.css";
const Logo = () => {
  return (
    <>
      <svg
        id="ultra-logo"
        width="489"
        height="109"
        viewBox="0 0 489 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.904 6.632V70.136C13.904 79.064 16.064 85.688 20.384 90.008C24.8 94.328 30.896 96.488 38.672 96.488C46.352 96.488 52.352 94.328 56.672 90.008C61.088 85.688 63.296 79.064 63.296 70.136V6.632H76.4V69.992C76.4 78.344 74.72 85.4 71.36 91.16C68 96.824 63.44 101.048 57.68 103.832C52.016 106.616 45.632 108.008 38.528 108.008C31.424 108.008 24.992 106.616 19.232 103.832C13.568 101.048 9.05599 96.824 5.69599 91.16C2.43199 85.4 0.799988 78.344 0.799988 69.992V6.632H13.904Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M111.364 0.440002V107H98.2599V0.440002H111.364Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M149.681 38.888V85.4C149.681 89.24 150.497 91.976 152.129 93.608C153.761 95.144 156.593 95.912 160.625 95.912H170.273V107H158.465C151.169 107 145.697 105.32 142.049 101.96C138.401 98.6 136.577 93.08 136.577 85.4V38.888H126.353V28.088H136.577V8.216H149.681V28.088H170.273V38.888H149.681Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M199.254 40.904C201.558 36.392 204.822 32.888 209.046 30.392C213.366 27.896 218.598 26.648 224.742 26.648V40.184H221.286C206.598 40.184 199.254 48.152 199.254 64.088V107H186.15V28.088H199.254V40.904Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M234.973 67.256C234.973 59.192 236.605 52.136 239.869 46.088C243.133 39.944 247.597 35.192 253.261 31.832C259.021 28.472 265.405 26.792 272.413 26.792C279.325 26.792 285.325 28.28 290.413 31.256C295.501 34.232 299.293 37.976 301.789 42.488V28.088H315.037V107H301.789V92.312C299.197 96.92 295.309 100.76 290.125 103.832C285.037 106.808 279.085 108.296 272.269 108.296C265.261 108.296 258.925 106.568 253.261 103.112C247.597 99.656 243.133 94.808 239.869 88.568C236.605 82.328 234.973 75.224 234.973 67.256ZM301.789 67.4C301.789 61.448 300.589 56.264 298.189 51.848C295.789 47.432 292.525 44.072 288.397 41.768C284.365 39.368 279.901 38.168 275.005 38.168C270.109 38.168 265.645 39.32 261.613 41.624C257.581 43.928 254.365 47.288 251.965 51.704C249.565 56.12 248.365 61.304 248.365 67.256C248.365 73.304 249.565 78.584 251.965 83.096C254.365 87.512 257.581 90.92 261.613 93.32C265.645 95.624 270.109 96.776 275.005 96.776C279.901 96.776 284.365 95.624 288.397 93.32C292.525 90.92 295.789 87.512 298.189 83.096C300.589 78.584 301.789 73.352 301.789 67.4Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M391.902 48.68V59.768H334.446V48.68H391.902Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M457.534 56.888L488.35 107H473.518L449.47 67.832L426.574 107H412.03L442.702 56.888L411.886 6.632H426.574L450.766 45.944L473.806 6.632H488.494L457.534 56.888Z"
          stroke="white"
          strokeWidth="5"
        />
      </svg>
    </>
  );
};

export default Logo;
