import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import "./modalStyle.css";

export const EditModalForm = (props) => {
  const [editedCompanyId, setEditedCompanyId] = useState("");
  const [editedCompanyName, setEditedCompanyName] = useState("");
  const [editedCompanyUxSales, setEditedCompanyUxSales] = useState("");
  const [editedCompanyUxtech, setEditedCompanyUxtech] = useState("");
  const [editedCompanyAdditionalInfo, setEditedCompanyAdditionalInfo] = useState("");
  const [userList, setUserList] = useState([]);

  const handleChangeUserListConversion = (userList) => {
    let userListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < userList.length; i++) {
      userListArr.push(Object.values(userList[i]).toString());
    }
    return userListArr;
  }

  useEffect(() => {
    // console.log("inside UseEffect");
    setEditedCompanyId(props.rowDataForEdit.company_id);
    setEditedCompanyName(props.rowDataForEdit.name);
    setEditedCompanyUxSales(props.rowDataForEdit.ux_sales);
    setEditedCompanyUxtech(props.rowDataForEdit.ux_tech);
    setEditedCompanyAdditionalInfo(props.rowDataForEdit.additional_info)
    setUserList(handleChangeUserListConversion(props.userList));
    
    // console.log(editedCompanyName);
    // console.log(editedCompanyUxSales);
    // console.log(editedCompanyAdditionalInfo);
  }, [props]);

  const onSelectUxSales = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxSales(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveUxSales = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxSales(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectUxTech = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxtech(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveUxTech = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxtech(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // console.log(editedCompanyId);
    // console.log(editedCompanyName);
    // console.log(editedCompanyUxSales);
    // console.log(editedCompanyUxtech);
    // console.log(editedCompanyAdditionalInfo);
    const updatedData = {
              company_id: editedCompanyId,
              name: editedCompanyName,
              ux_sales: editedCompanyUxSales,
              ux_tech: editedCompanyUxtech,
              additional_info: editedCompanyAdditionalInfo,
            };
    props.handleTableDataEdit(updatedData);
    props.onHide();
  };
  // console.log(props.rowDataForEdit.name);
  // console.log(props.rowDataForEdit.company_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden" , background: "202124" }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Company Information
        </Modal.Title>
      </Modal.Header>
      {/* <SimpleBar forceVisible="y" autoHide={true}>  */}
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
        <h4>Company ID: {props.rowDataForEdit.company_id}</h4>
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={`${editedCompanyName}`}
              onChange={(e) => setEditedCompanyName(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>UX_Sales</Form.Label>
            <Multiselect
              selectedValues={`${props.rowDataForEdit.ux_sales}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveUxSales}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectUxSales}
              options={userList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>UX_tech</Form.Label>
            {/* <Form.Control
              value={`${editedProductUxtech}`}
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              selectedValues={`${props.rowDataForEdit.ux_tech}`.split(", ")}
              showCheckbox
              hidePlaceholder={false}
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveUxTech}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectUxTech}
              options={userList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Additional Information</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={`${editedCompanyAdditionalInfo}`}
              onChange={(e) => setEditedCompanyAdditionalInfo(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Save
          </Button>
        </Form>
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export const AddModalForm = (props) => {
  // const [editedProductId, setEditedProductId] = useState("");
  const [editedCompanyName, setEditedCompanyName] = useState("");
  const [editedCompanyUxSales, setEditedCompanyUxSales] = useState("");
  const [editedCompanyUxtech, setEditedCompanyUxtech] = useState("");
  const [editedCompanyAdditionalInfo, setEditedCompanyAdditionalInfo] = useState("");
  const [userList, setUserList] = useState([]);

  const handleChangeUserListConversion = (userList) => {
    let userListArr = [];
      // userListArr.push(Object.values(data[0]));
      // userListArr.push(Object.values(data[1]));
      // console.log(userListArr[]);
      for(let i = 0; i < userList.length; i++) {
      userListArr.push(Object.values(userList[i]).toString());
    }
    return userListArr;
  }
  useEffect(() => {
    setUserList(handleChangeUserListConversion(props.userList));
    
    // console.log("inside UseEffect");
    // setEditedProductId(props.rowDataForEdit.product_id);
    // setEditedProductName(props.rowDataForEdit.name);
    // setEditedProductUxSales(props.rowDataForEdit.ux_sales);
    // setEditedProductUxtech(props.rowDataForEdit.ux_tech);
    // console.log(editedProductName);
    // console.log(editedProductUxSales);
  }, [props]);

  const onSelectUxSales = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxSales(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveUxSales = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxSales(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSelectUxTech = (selectedList, selectedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxtech(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onRemoveUxTech = (selectedList, removedItem) => {
    // console.log(selectedList);
    setEditedCompanyUxtech(
      selectedList.toString().replace(/,(?=[^\s])/g, ", ")
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("This are all the data");
    // console.log(editedProductId);
    // console.log(editedCompanyName);
    // console.log(editedCompanyUxSales);
    // console.log(editedCompanyUxtech);
    const addData = {
      name: editedCompanyName,
      ux_sales: editedCompanyUxSales,
      ux_tech: editedCompanyUxtech,
      additional_info: editedCompanyAdditionalInfo,
    };
    props.handleNewTableDataAdd(addData);
    props.onHide();
  };
  //console.log(props.rowDataForEdit.name);
  //console.log(props.rowDataForEdit.product_id);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="editModel"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ width: "535px", margin: "auto", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", overflow: "hidden", background: "202124"  }}
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{
          margin: "0px",
          paddingTop: "2px",
          paddingBottom: "2px",
          background: "#202124",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Add Company Information
        </Modal.Title>
      </Modal.Header>
      <SimpleBar style={{height: "fit-content", maxHeight: '80vh'}} forceVisible= "y">
      <Modal.Body style={{ background: "#202124", color: "white" }}>
        <h4>New Conpamy Entry</h4>
        <Form
          style={{ margin: "0px", width: "600px", maxHeight: "fit-content" }}
          onSubmit={onSubmit}
        >
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              // value={`${editedProductName}`}
              placeholder="Enter Company Name"
              onChange={(e) => setEditedCompanyName(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>UX_Sales</Form.Label>
            <Multiselect
              // selectedValues={[`${props.rowDataForEdit.ux_sales}`]}
              showCheckbox
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveUxSales}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectUxSales}
              options={userList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>UX_tech</Form.Label>
            {/* <Form.Control
              // value={`${editedProductUxtech}`}
              placeholder="Enter ux_tech"
              onChange={(e) => setEditedProductUxtech(e.target.value)}
              required
            /> */}
            <Multiselect
              // selectedValues={[`${props.rowDataForEdit.ux_sales}`]}
              showCheckbox
              closeOnSelect={false}
              isObject={false}
              avoidHighlightFirstOption={true}
              onRemove={onRemoveUxTech}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectUxTech}
              options={userList}
              style={{
                chips: {
                  background: "#4C4D4F",
                },
                multiselectContainer: {
                  color: "black",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid blue",
                  "border-radius": "0px",
                  background: "white",
                },
              }}
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-1" controlId="formBasicEmail">
            <Form.Label>Additional Information</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter Additional Information"
              // value={`${editedCompanyAdditionalInfo}`}
              onChange={(e) => setEditedCompanyAdditionalInfo(e.target.value)}
              required
            />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: "1rem", marginLeft: "40%" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            Add
          </Button>
        </Form>
      </Modal.Body>
      </SimpleBar>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};
