import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, {forwardRef, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { GoDiffAdded } from "react-icons/go";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { MdBrightness2 } from "react-icons/md";
import {
  HandleAddAPI, HandleAllDataReadAPI,
  HandleDataCountAPI,
  HandleDeleteAPI,
  HandleEditAPI,
  handleGetUserListAPI
} from "./HandleAPIFunctions";
import { AddModalForm, EditModalForm } from "./PopUpModalForm";
import {
  AddSuccessMessage, DeleteSuccessMessage, EditSuccessMessage, ErrorMessage, Loading, WarningMessageForForAddRequiredData
} from "./TableActionAlert";
import tableColumns from "./TableColumns";




const ProductTable = (props) => {
  const [countData, setCountData] = useState(0);
  const [userList, setUserList] = useState([]);
  const [data, setDataList] = useState([]);
  // const [paginationArray, setPaginationArray] = useState([]);
  const [preferDarkMode, setPreferDarkMode] = useState(() => {
    const mode = localStorage.getItem("_tableDarkMode");
    return mode === "true" || false;
  });
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [lastUpdateId, setLastUpdateId] = useState(null);
  const [warningForAddRequiredData, setWarningForAddRequiredData] =
    useState(false);
  const [editModalFormShow, SetEditModalFormShow] = useState(false);
  const [rowDataForEdit, SetRowDataForEdit] = useState({});
  const [addModalFormShow, SetAddModalFormShow] = useState(false);



  useEffect(() => {
    handlecountData();
    handleClearMessage();
    handleTableDataShow();
    handleGetUserList();
    
  }, []);

  const theme = createTheme({
    palette: {
      type: preferDarkMode ? "dark" : "light",
    },
  });

  const handleDarkModeChange = () => {
    setPreferDarkMode((prev) => !prev);
    localStorage.setItem("_tableDarkMode", !preferDarkMode);
  };


  const handleGetUserList = () => {
    const ifSuccess = (userList) => {
      setUserList(userList);
      // console.log("data INNNNNN")
      // console.log(userList);
      
      // console.log("data OUTTTTT")
    };
    handleGetUserListAPI(props.userAuthInfo, ifSuccess)

  }

  const handlecountData = () => {
    const ifSuccess = (countValue) => {
      setCountData(countValue);
    };
    const ifError = () => {
      setCountData(0);
    };
    HandleDataCountAPI(props.userAuthInfo, ifSuccess, ifError);
  };

  const handleTableDataShow = () => {
    setTableLoading(true);
  
    const ifSuccess = (allData) => {
      setDataList(allData);
      let A = ["wer", "sese"]
      // console.log(data);
      // console.log("all data set kora sesh n pagination o korlam: " + A);
      // paginationArrayMakerFun()
      setTableLoading(false);
      // return allData;
    };
    const ifError = () => {
      setError(true);
    };
    HandleAllDataReadAPI(props.userAuthInfo, ifSuccess, ifError);
    //return ans;
  };

  const handleNewTableDataAdd = (name, ux_sales, ux_tech) => {
    handleClearMessage();
    const ifSuccess = (product_id) => {
      handleTableDataShow();
      setLastUpdateId(`${product_id}`);
      setAddSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    const addData = {
      name,
      ux_sales,
      ux_tech,
    };
    HandleAddAPI(props.userAuthInfo, addData, ifSuccess, ifError);
  };

  const handleTableDataEdit = (product_id, name, ux_sales, ux_tech) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${product_id}`);
      setEditSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    const editData = {
      product_id,
      name,
      ux_sales,
      ux_tech,
    };

    HandleEditAPI(props.userAuthInfo, editData, ifSuccess, ifError);
  };

  const handleTableDataDelete = (product_id) => {
    handleClearMessage();
    const ifSuccess = () => {
      handleTableDataShow();
      setLastUpdateId(`${product_id}`);
      setDeleteSuccess(true);
    };
    const ifError = () => {
      setError(true);
    };
    HandleDeleteAPI(props.userAuthInfo, `${product_id}`, ifSuccess, ifError);
  };

  const handleClearMessage = () => {
    setError(false);
    setWarningForAddRequiredData(false);
    setEditSuccess(false);
    setDeleteSuccess(false);
    setAddSuccess(false);
    setLastUpdateId(null);
  };
  const handleHideEditModal = () => {
    SetEditModalFormShow(false);
    SetRowDataForEdit({});
  }

  const tableIcons = {
    Delete: forwardRef((props, ref) => <MdDeleteOutline {...props} ref={ref} className="tableIcon"/>),
    // edit: forwardRef((props, ref) => <FiEdit {...props} ref={ref} className="tableIcon"/>),
  }
  const tableRef = React.createRef();

  const paginationArrayMakerFun = () => {
    let paginationArrayMaker = [];
    for(let i =5; i< data.length ; i=i+5){
      paginationArrayMaker.push(i);
    }
    paginationArrayMaker.push(data.length);
    // setPaginationArray(paginationArrayMaker);
    //  setTableLoading(false);
    // console.log(paginationArray);
    return paginationArrayMaker;
  }

  return (
    <>
      <ErrorMessage error={error} handleClearMessage={handleClearMessage} />
      <WarningMessageForForAddRequiredData
        error={warningForAddRequiredData}
        handleClearMessage={handleClearMessage}
      />
      <EditSuccessMessage
        editSuccess={editSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <DeleteSuccessMessage
        deleteSuccess={deleteSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <AddSuccessMessage
        addSuccess={addSuccess}
        handleClearMessage={handleClearMessage}
        lastUpdateId={lastUpdateId}
      />
      <EditModalForm
        userList={userList}
        rowDataForEdit={rowDataForEdit}
        handleTableDataEdit={handleTableDataEdit}
        show={editModalFormShow}
        //onHide={() => SetEditModalFormShow(false)}
        onHide={handleHideEditModal}
      />
      <AddModalForm
        userList={userList}
        handleNewTableDataAdd={handleNewTableDataAdd}
        show={addModalFormShow}
        onHide={() => SetAddModalFormShow(false)}
      />
      {tableLoading && <Loading tableLoading={tableLoading} />}
      {!tableLoading && (
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            title={
              <h4 style={{ fontSize: "1.2rem" }}>Total Entries: {countData}</h4>
            }
            style={{
              width: "100%",
              // paddingTop: "0px",
              // marginTop: "0px",
              // maxWidth: "90% !important",
              display: "inline-table",
              height: "90%",
              borderRadius: "2rem",
              fontSize: "inherit",
            }}
            data={data}
            // data={query =>
            //   new Promise(async (resolve, reject) => { 
            //     console.log(query)
            //     let result_page_number = (query.page + 1);
            //     let result_per_page= query.pageSize;
            //     let ans;
            //   // await handleTableDataShow(result_page_number, result_per_page).then( res => {
            //         resolve({
            //           data: data,
            //           page: result_page_number,
            //           totalCount: countData,
            //         })
            //       })
            //   //  })
                 
             
            // }
            columns={tableColumns}
            // detailPanel={[
            //   {
            //     tooltip: "Show body",
            //     render: (rowData) => {
            //       return (
            //         <div
            //           style={{
            //             margin: "20px",
            //             fontSize: 20,
            //             textAlign: "center",
            //             color: "black",
            //             backgroundColor: "white",
            //           }}
            //         >
            //           {rowData.content}
            //         </div>
            //       );
            //     },
            //   },
            // ]}
            editable={{
              // onRowAdd: (newRow) =>
              //   new Promise((res, rej) => {
              //     console.log(newRow);
              //     if (newRow.name && newRow.ux_sales && newRow.ux_tech) {
              //       handleNewTableDataAdd(
              //         newRow.name,
              //         newRow.ux_sales,
              //         newRow.ux_tech
              //       );
              //       res();
              //     } else {
              //       setWarningForAddRequiredData(true);
              //       rej();
              //     }
              //   }),
              onRowDelete: (selectedRow) =>
                new Promise((res, rej) => {
                  // console.log(selectedRow.product_id);
                  handleTableDataDelete(selectedRow.product_id);
                  res();
                }),
              // onRowUpdate: (updatedRow, oldRow) =>
              //   new Promise((res, rej) => {
              //     console.log(updatedRow);
              //     handleTableDataEdit(
              //       updatedRow.product_id,
              //       updatedRow.name,
              //       updatedRow.ux_sales,
              //       updatedRow.ux_tech
              //     );
              //     res();
              //   }),
            }}
            options={{
              pageSize: data.length,
              pageSizeOptions: paginationArrayMakerFun(),
              // pageSizeOptions: [5, 10, 20, `${data.length}`],
              actionsColumnIndex: -1,
              addRowPosition: "first",
              //selection: true,
             // paging: false,
              headerStyle: {
                position: "sticky",
                top: "0",
                fontSize: "1.1rem",
                fontWeight: "900",
                width: "0rem",
                paddingLeft: "0px",
                paddingRight: "0px",
                //backgroundColor: "#039be5",
                backgroundColor: "rgb(77, 121, 255)",
                height: "5rem"
              },
              maxBodyHeight: "60vh",
            }}
            actions={[
              {
                icon: () => <GoDiffAdded className="tableIcon"/>,
                tooltip: "Add User",
                isFreeAction: true,
                onClick: (event) => {
                  SetAddModalFormShow(true);
                },
              },
              (rowData) => ({
                icon: () => <FiEdit className="tableIcon"/>,
                tooltip: "Edit",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  SetRowDataForEdit(rowData);
                  SetEditModalFormShow(true);
                  // console.log(rowData);
                  //Do whatever you want with the row clicked data.
                },
              }),
              // (rowData) => ({
              //   icon: "add",
              //   tooltip: "Add",
              //   onClick: (event, rowData) => {
              //     console.log(rowData);
              //     //Do whatever you want with the row clicked data.
              //   },
              // }),
              // {
              //   icon: "edit",
              //   onClick: (rowData) => {
              //     console.log(rowData)
              //   },
              // },
              //   {
              //   icon: 'add',
              //   isFreeAction: true,
              //   onClick: () => {
              //     console.log("ADDDD");
              //   }
              // },
              // {
              //   hidden: true,
              //   tooltip: "Remove All Selected Users",
              //   icon: "delete",
              //   onClick: (evt, data) => {
              //     alert("You want to delete " + data.length + " rows");
              //     console.log(data);
              //   },
              // },

              {
                icon: () =>
                  //preferDarkMode ? <Brightness7Icon /> : <Brightness4Icon />,
                  preferDarkMode ? (
                    <BsFillBrightnessHighFill className="tableIcon" />
                  ) : (
                    <MdBrightness2 className="tableIcon"/>
                  ),
                tooltip: "Toggle Light/Dark Mode",
                onClick: handleDarkModeChange,
                isFreeAction: true,
              },
            ]}
            // components={{
            //   Action: (props) => {
            //     if (props.action.icon === "edit") {
            //       return (
            //         <Button
            //           onClick={(event) =>
            //             props.action.onClick(event, props.data)
            //           }
            //           color="primary"
            //           variant="contained"
            //           style={{ textTransform: "none" }}
            //           size="small"
            //           disabled
            //         >
            //           My Button
            //         </Button>
            //       );
            //     }
            //     // if (props.action.icon === "save") {
            //     //   return (
            //     //     <Button
            //     //       onClick={(event) =>
            //     //         props.action.onClick(event, props.data)
            //     //       }
            //     //       color="primary"
            //     //       variant="contained"
            //     //       style={{ textTransform: "none" }}
            //     //       size="small"
            //     //     >
            //     //       My Button
            //     //     </Button>
            //     //   );
            //     // }
            //   },
            // }}
          />
        </MuiThemeProvider>
      )}
    </>
  );
};

export default ProductTable;
