//import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {
  Card, Col, ListGroup, ListGroupItem, Row
} from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import Update from "./Update";

// Profile component start
const Profile = (props) => {
  //console.log(props.userAuthInfo);
  //const location = useLocation();
  //console.log(location.state); // it is equal to yourData
  //const [username, setUsername] = useState({ ...props.username });
  //const [username, setUsername] = useState({ ...props.username });
  //const[token,setToken] = useState({...props.user.token);
  const [user, setUser] = useState([]);
  //const[token, setToken] = useState("");
  //console.log(username);
  //console.log(user);

  //let item = (location.state);
  let item = {
    username: `${props.userAuthInfo.userName}`,
    token: `${props.userAuthInfo.token}`,
  };
   //profile funciton start
  //profile function return user or admin information through the API request
  async function profile() {
    let result = await fetch(
      "https://bddev.ultra-x.jp/vipnoteApi/get_user_info",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    setUser(result);
    //console.log(result);
    // localStorage.setItem("user-info", JSON.stringify(result));
  }
  //profile function end
  useEffect(() => {
    profile();
  }, []);

  return (
    <div>
      <Row>
        <Col>
          <div>
            <Card className="card"style={{ background:"#202124" }}>
              
                {/* <div className="img1">
                  <Card.Img variant="top" height="80px" src="vipnote2.1.png" />
                  </div> */}
                  <div>
                <Card.Title className="userInfo">User Information</Card.Title>
                </div>
               <div>
              <ListGroup className="userInfoList">
                <ListGroupItem className="userInfoListItem">
                  <span className="profilelist1">User Id:</span>
                  <span className="profilelist"> {user.user_id}</span>
                </ListGroupItem>
                <ListGroupItem className="userInfoListItem">
                  <span className="profilelist1">UserName:</span>{" "}
                  <span className="profilelist">{user.username}</span>
                </ListGroupItem>
                <ListGroupItem className="userInfoListItem">
                  <span className="profilelist1">FullName:</span>
                  <span className="profilelist">{user.fullname}</span>{" "}
                </ListGroupItem>
                <ListGroupItem className="userInfoListItem">
                  <span className="profilelist1">Email: </span>
                  <span className="profilelist">{user.email}</span>
                </ListGroupItem>
                <ListGroupItem className="userInfoListItem">
                  <span className="profilelist1">Department:</span>{" "}
                  <span className="profilelist">{user.department}</span>
                </ListGroupItem>
              </ListGroup>
              </div>
              <div>
              <Update func={profile} user={user} item={item} />
              </div>
              <div>
              <ChangePassword func={profile} item={item} />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Profile;
